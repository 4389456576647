import React from "react"
import styled from "styled-components"

import { Modal, Box, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import AppLayout from "@components/layout/App"
import SearchTypeahead from "@components/modules/SearchTypeahead"

import BgLanding from "@assets/bg-landing.jpg"

import JOB_FAMILY_GROUPS from "@content/job-families"

import UnstyledLink from "@components/elements/UnstyledLink"
import { getJobFamilyUrl } from "@helpers/url"

import theme from "@assets/styles/theme"
import CloseIcon from "@material-ui/icons/Close"

const HomeContainer = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
`

const ContentWrapper = styled.div`
  color: ${theme.colors.grey.white};
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: url(${BgLanding});
  background-size: cover;
  background-position: center;
  padding: 40px 0;
`

const PageTitle = styled.div`
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  margin-bottom: 8px;
  color: ${theme.colors.grey.darkGray};
  @media (max-width: 1023px) {
    font-size: 1.5rem;
    line-height: 28px;
    margin-bottom: 0.5rem;
  }
`

const PageSubtitle = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  color: ${theme.colors.grey.darkGray};

  margin-bottom: 24px;

  @media (max-width: 1023px) {
    font-size: 12px;
  }
`

const FlexContainer = styled.div`
  display: flex;
  gap: 16px;
  width: min(1140px, calc(100vw - 32px));
  flex-wrap: wrap;
  @media (max-width: 1023px) {
    display: none;
  }
`

const LinksContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;

  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: stretch;
  }
`

const BaseTileBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  cursor: pointer;
`

const LinkTileBox = styled(BaseTileBox)`
  background-color: ${theme.colors.primary.blue};
  color: white;
  width: 273px;
  height: 48px;
  border-radius: 4px;

  &.how-to {
    @media (max-width: 1023px) {
      background-color: ${theme.colors.secondary.yellow};
      color: ${theme.colors.grey.black};
    }
  }

  @media (max-width: 1023px) {
    width: 100%;
  }
`

const SearchFlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex-wrap: nowrap;
  width: min(1140px, calc(100vw - 32px));
  margin-bottom: 18px;

  @media (max-width: 1023px) {
    margin-bottom: 40px;
  }
`

const LinksSearchContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1023px) {
    flex-direction: column-reverse;
    align-items: stretch;
  }
`

const TileBox = styled(BaseTileBox)`
  color: black;
  background: ${theme.colors.secondary.green};
  flex-direction: column;
  height: 48px;
  width: calc(33% - 8px);

  @media (max-width: 1023px) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    padding: 10px 16px;

    border-top: 0;
    border-left: ${props => `5px solid ${props.color}`};
  }
`

const DescriptionBox = styled(Box)`
  display: block;
  @media (max-width: 1023px) {
    display: none;
  }
`

const getModalStyle = () => {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  "how-to-link": {
    marginRight: "16px",
    "@media (max-width: 1023px)": {
      marginRight: "0",
      marginBottom: "8px",
    },
  },
}))

const ModalListItemBox = styled.div`
  color: ${theme.colors.grey.darkGray};

  padding: 14px 12px;
  background: ${theme.colors.secondary.avocado};
  text-align: center;

  display: flex;
  flex-direction: column;

  min-width: 250px;
  margin: 0.5rem;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  border-radius: 4px;
`

const ModalCloseButton = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;

  cursor: pointer;
  margin: 0;
  background-color: transparent;
  border: 0;
  color: ${theme.colors.grey.black};
`

export default function Home() {
  const modalClasses = useStyles()
  const [modalStyle] = React.useState(getModalStyle)
  const [modalOpen, setModalOpen] = React.useState(false)
  const [modalJobFamilyGroup, setModalJobFamilyGroup] = React.useState({
    jobFamilies: [],
  })

  const handleModalOpen = jobFamilyGroup => {
    setModalOpen(true)
    setModalJobFamilyGroup(jobFamilyGroup)
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const SelectJobFamilyModal = () => {
    return (
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={modalClasses.paper}>
          <Box display={"flex"} justifyContent={"center"} position="relative">
            <Box
              style={{
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",
              }}
              py={1}
            >
              Select a Job Family
            </Box>
            <ModalCloseButton onClick={() => handleModalClose()}>
              <CloseIcon />
            </ModalCloseButton>
          </Box>
          <div style={{ padding: "4px" }}>
            {modalJobFamilyGroup.jobFamilies.map(item => {
              return (
                <UnstyledLink
                  to={getJobFamilyUrl(modalJobFamilyGroup.slug, item.slug)}
                  key={`job-family-${item.slug}`}
                >
                  <ModalListItemBox>{item.name}</ModalListItemBox>
                </UnstyledLink>
              )
            })}
          </div>
        </div>
      </Modal>
    )
  }

  return (
    <AppLayout simpleHeader={true}>
      <HomeContainer>
        <ContentWrapper>
          <PageTitle>HMSA Career Navigator</PageTitle>
          <PageSubtitle>Explore the Career Framework for HMSA</PageSubtitle>
          <LinksSearchContainer>
            <LinksContainer>
              <UnstyledLink to="/career-bands">
                <LinkTileBox>Click Here to See Our Career Levels</LinkTileBox>
              </UnstyledLink>
            </LinksContainer>

            <SearchFlexContainer>
              <SearchTypeahead style={{ width: "100%" }} grey={false} />
            </SearchFlexContainer>
          </LinksSearchContainer>

          <DescriptionBox
            bgcolor={"#000"}
            style={{ opacity: "70%" }}
            p="8px 0"
            width="min(1140px, calc(100vw - 32px))"
            mb={1}
            fontSize="14px"
          >
            To view the job families, click on one of the green boxes below.
          </DescriptionBox>
          <FlexContainer
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            {JOB_FAMILY_GROUPS.map((jfg, index) => {
              return jfg ? (
                <TileBox
                  key={`job-family-group-box-${index}`}
                  index={index}
                  onClick={() => {
                    handleModalOpen(jfg)
                  }}
                >
                  <p>{jfg.jobFamilyGroupName}</p>
                </TileBox>
              ) : (
                <div key={`job-family-group-box-${index}`}></div>
              )
            })}
          </FlexContainer>
        </ContentWrapper>
      </HomeContainer>

      <SelectJobFamilyModal />
    </AppLayout>
  )
}
